import React from 'react'
// import Logo from "../assets/img/upmyloan.png"
// import Location from "../assets/img/location.png"
import Mail from "../assets/img/mail.png"
import Logowhite from "../assets/img/upmyloan.png"
import Facebook from "../assets/img/facebook.svg"
import Twitter from "../assets/img/twitter.svg"
import Instagram from "../assets/img/instagram.svg"
import LinkedIn from "../assets/img/linkedin.svg" 
import YouTube from "../assets/img/youtube.svg" 
import TikTok from "../assets/img/Tiktok.svg" 
// import SnapChat from "../assets/img/Snapchat.svg" 
// import Medium from "../assets/img/Medium.svg" 
 

const FooterLinks = () => {

    const fterLinks = [
        { text: 'About Us', url: '/aboutus' }, 
        { text: 'Blogs', url: 'https://blog.upmyloan.com' },   
        { text: 'Why Choose Us', url: '/whychooseus' },
        { text: 'Contact Us', url: '/contactus' }, 
        { text: 'Terms of Service', url: '/termsofservice' },
        { text: 'Privacy Policy', url: '/privacypolicy' },
        { text: 'Disclaimer', url: '/disclaimer' },
        { text: 'eConsent', url: '/econsent' }, 
        
          
      ];

      const fterLinksOther = [ 
        { text: 'Lending Policy', url: '/lendingpolicy' } ,
        { text: 'Definition ', url: '/definition' },  
        { text: 'Rates and Fees ', url: '/ratesandfees' },
        { text: 'Full Disclosure of Terms ', url: '/fulldisclosureofterms' },
        { text: 'Late Payment Policy ', url: '/latepaymentpolicy' },   
        { text: 'Non-Payment Policy ', url: '/nonpaymentpolicy' },  
        { text: 'Loan Renewal Policy ', url: '/loanrenewalpolicy' },  
        
          
      ];
 
      const socialLinks = [
        { text: 'Facebook', url: 'https://www.facebook.com/upmyloan', icon: Facebook },
        { text: 'Twitter', url: 'https://x.com/upmyloan', icon: Twitter },
        { text: 'Instagram', url: 'https://www.instagram.com/upmyloan.official/', icon: Instagram },
        { text: 'LinkedIn', url: 'https://www.linkedin.com/company/102901846/', icon: LinkedIn }, 
        { text: 'YouTube', url: 'https://www.youtube.com/@upmyloan', icon: YouTube }, 
        { text: 'TikTok', url: 'https://www.tiktok.com/@upmyloan', icon: TikTok }, 
        // { text: 'SnapChat', url: '/', icon: SnapChat }, 
        // { text: 'Medium', url: '/', icon: Medium }, 
      ];      
      
    const items = [
        // { 
        //   desc: '456 Maple Avenue, Suite 200, Smalltown, USA', 
        //   icon: Location,
        //  },
         { 
          desc: 'care @ upmyloan.com', 
          icon: Mail,
         }  
         

      ];
  return (
    <div className='mt-4'>
    <div className='container'>
    <div className='row'>
    <div className='col-md-4 col-12'>
    <div className='ftrAbout'>
      <img src={Logowhite} width={200} alt='Logo'/>
      <p>
      Our goal at UpMyLoan Company is to provide access to personal loans and education loan, car loan, home loan at insight competitive interest rates. We are the loan provider, you can use our loan product.
      </p>
 <div className='row'>
      {items.map((item, index) => ( 
    <div key={index} className='col-md-6 col-12'> 
    <div className="d-flex align-self-center" >
  <img  src={item.icon} alt={item.title} width={22} height={22} className='my-3' />
   
    <p className="textPara ms-2"> 
        {item.desc}
        </p>
 
</div> 
    </div>
     ))}
     </div>

    </div>
    </div>
    <div className='col-md-3 col-12'>
        <h4 className='textGreen fw-semibold fs-4'>Quick Links</h4>
    <ul className='ftLinks'>
        {fterLinks.map((link, index) => (
          <li key={index}>
            <a className='fs-6' href={link.url}>{link.text}</a>
          </li>
        ))}
      </ul>
    </div>
    <div className='col-md-3 col-12'>
        <h4 className='textGreen fw-semibold fs-4'>Quick Links</h4>
    <ul className='ftLinks'>
        {fterLinksOther.map((link, index) => (
          <li key={index}>
            <a className='fs-6' href={link.url}>{link.text}</a>
          </li>
        ))}
      </ul>
    </div>
   
    <div className='col-md-2 col-12'>
    <h4 className='textGreen fw-bold fs-4'>Social Media</h4> 
    <ul className='ftLinks'>
        {socialLinks.map((link, index) => (
          <li key={index}>
            <a href={link.url} target='_blank' className='fs-6' rel="noreferrer">
                <img src={link.icon} className='me-2' width={22} alt='Social Media'/>
                {link.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
    <div className='clearfix'></div>

    <div className='copyRights'>
        <p>© <strong>upmyloan.com</strong>. All rights reserved</p>
     </div>
    </div>  
    

     </div>
    </div>
  )
}

export default FooterLinks
